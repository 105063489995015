.container {
  width: 100%;
  max-width: 1440px;
  margin: 2rem auto;
}
.containerFull {
  max-width: 100%;
}
.containerSmall {
  max-width: 900px;
}
.noMargin {
  margin: 0 auto;
}

@media screen and (max-width: 1440px) {
  .container {
    padding: 0 1rem;
  }
}

main > .container:first-child {
  margin-top: 12rem;
}
