.paragraph {
  font-size: 1rem;
  font-family: ITCJohnstonLight;
  line-height: 1.75;
  color: var(--text-color);
  margin: 5rem auto;
  p {
    margin-bottom: 1rem;
    &:last-child {
      margin-bottom: none;
    }
  }
  &.altFont {
    font-family: BauerBodoniItalic;
  }
  &.left {
    text-align: left;
  }
  &.center {
    text-align: center;
  }
  &.right {
    text-align: right;
  }
  @media screen and (max-width: 767px) {
    margin: 2rem auto;
    line-height: 2;
  }
}
