.adminCollection {
  padding: 1rem;
  display: flex;
  flex-direction: column;
  h2 {
    width: 100%;
    max-width: 1440px;
    margin: 1rem auto;
    margin-bottom: 2rem;
    font-family: ITCJohnstonLight;
    font-weight: 100;
    text-transform: uppercase;
    letter-spacing: 1px;
  }
}

.userActions {
  margin-bottom: 2rem;
  display: flex;
  a {
    margin-left: auto;
    padding: 0.5rem 1rem;
    background: lightskyblue;
    border: none;
    border-radius: 0.5rem;
    font-size: 0.8rem;
    color: #fafafa;
    display: flex;
    justify-content: center;
    align-items: center;
    font-family: "Gill Sans", "Gill Sans MT", Calibri, "Trebuchet MS",
      sans-serif;
    font-weight: 500;
    text-transform: uppercase;
    letter-spacing: 1px;
    cursor: pointer;
  }
}
.collectionContent {
  display: flex;
  flex-direction: column;
  .emptyList {
    padding: 1rem;
    background-color: #e7e7e7;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    p {
      width: 100%;
      max-width: 600px;
      text-align: center;
      margin: 0.5rem 0;
      color: #171717;
    }
  }

  .collectionList {
    width: 100%;
    display: flex;
    flex-direction: column;
    .collectionListHeader {
      display: flex;
      background-color: var(--primary);
      font-weight: 500;
      div {
        padding: 0.5rem;
        flex: 1;
        display: flex;
        justify-content: flex-start;
        align-items: center;
        font-family: "Gill Sans", "Gill Sans MT", Calibri, "Trebuchet MS",
          sans-serif;
        font-weight: inherit;
        &[data-type="id"],
        &[data-type="updatedAt"],
        &[data-type="createdAt"] {
          display: none;
        }
        &[data-type="thumbnail"] {
          order: -1;
          max-width: 150px;
        }
        &[data-type="actions"] {
          flex: 0;
          width: 3rem;
          color: transparent;
          opacity: 0;
          visibility: hidden;
        }
      }
    }
  }

  .saveBtn {
    margin-top: 2rem;
    margin-left: auto;
    padding: 0.5rem 1rem;
    background: lightskyblue;
    border: none;
    border-radius: 0.5rem;
    color: #fafafa;
    display: flex;
    justify-content: center;
    align-items: center;
    font-family: "Gill Sans", "Gill Sans MT", Calibri, "Trebuchet MS",
      sans-serif;
    font-weight: 500;
    text-transform: uppercase;
    letter-spacing: 1px;
    cursor: pointer;
    &.disabled {
      background-color: lightsteelblue;
    }
  }
}
