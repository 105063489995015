.collectionListItem {
  display: flex;
  background-color: var(--white);
  font-weight: 300;
  margin-bottom: 2px;
  &:nth-of-type(odd) {
    background-color: var(--primary-lightest);
  }
  &:last-of-type {
    &:nth-of-type(even) {
      border-bottom: 2px solid var(--primary-lightest);
    }
  }
  div {
    padding: 0.75rem 0.5rem 0.5rem;
    flex: 1;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    font-family: ITCJohnstonLight;
    font-weight: 100;
    &[data-type="id"],
    &[data-type="updatedAt"],
    &[data-type="createdAt"] {
      display: none;
    }
    &[data-type="thumbnail"] {
      order: -1;
      max-width: 150px;
      img {
        width: 100%;
        max-width: 100%;
        height: auto;
      }
    }
  }
  .editItem,
  .deleteItem {
    flex: 0;
    width: 3rem;
    button {
      border: none;
      background: none;
      cursor: pointer;
      padding: 0.5rem;
      svg {
        width: 1.5rem;
        height: 1.5rem;
      }
    }
  }
  .editItem {
    svg {
      stroke: #44617b;
      &:hover {
        stroke: #364d62;
      }
    }
  }
  .deleteItem {
    svg {
      stroke: #e50000;
      &:hover {
        stroke: #b20000;
      }
    }
  }
}
