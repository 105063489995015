.adminCollectionEditItem {
  padding: 1rem;
  display: flex;
  flex-direction: column;
  h2 {
    font-family: ITCJohnstonLight;
    font-weight: 100;
    text-transform: uppercase;
    letter-spacing: 1px;
    max-width: 1440px;
    margin: 1rem auto 1.5rem;
  }
  .langSelector {
    width: 100%;
    max-width: 1440px;
    margin: 0 auto;
    display: flex;
    button {
      padding: 0.75rem 1rem 0.5rem;
      display: block;
      font-size: 1rem;
      border-radius: 0;
      border: none;
      background-color: #e5eded;
      color: var(--text-color);
      cursor: pointer;
      font-family: ITCJohnstonLight;
      font-weight: 100;
      &.active {
        background-color: #fafafa;
        color: var(--black);
        cursor: default;
        pointer-events: none;
      }
    }
  }
  form {
    width: 100%;
    max-width: 1440px;
    margin: 0 auto;
    background-color: #fafafa;
    border-radius: 5px;
    border: none;
    margin-bottom: 2rem;
    padding: 1rem 0.5rem;
    fieldset {
      border: 0;
      margin-bottom: 1rem;
      display: flex;
      label {
        flex: 0 0 20%;
        font-family: ITCJohnstonLight;
        font-weight: 100;
        padding: 0.75rem 0.5rem 0.5rem;
        padding-right: 1rem;
        font-size: 0.9rem;
        min-width: 13rem;
      }
      input {
        flex: 0 0 80%;
        padding: 0.75rem 0.5rem 0.5rem;
        font-size: 0.9rem;
        font-family: ITCJohnstonLight;
        font-weight: 100;
        border: none;
        border-bottom: 1px solid #e5eded;
        &:disabled {
          background-color: #e5eded;
          font-style: italic;
        }
        &:focus {
          outline: 2px solid var(--primary);
        }
        &[type="file"] {
          border: none;
          padding: 0;
          &:focus {
            outline: none;
          }
        }
      }
    }
    .preview {
      flex: 0 0 80%;
    }
  }
}
