.blockSelector {
  display: flex;
  align-items: center;
  flex-direction: row;
  margin: 1rem 0 2rem;
  padding-bottom: 2rem;
  border-bottom: 5px solid #e5eded;
  h4 {
    flex: 0 0 15%;
    font-family: ITCJohnstonLight;
    font-weight: 100;
    text-transform: uppercase;
    font-size: 0.9rem;
  }
  div {
    flex: 0 0 85%;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    gap: 1rem;
    flex-wrap: wrap;
    button {
      display: block;
      white-space: nowrap;
      font-family: ITCJohnstonLight;
      font-weight: 100;
      margin-left: 0;
      font-size: 0.7rem;
    }
  }
}
