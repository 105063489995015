.instagramFeed {
  h2 {
    text-align: center;
    margin-bottom: 2rem;
    font-size: 3rem;
    color: var(--gray);
    font-family: BauerBodoniBold;
  }
  .feedGrid {
    display: grid;
    grid-template-columns: repeat(5, 1fr);
    gap: 3rem;
    .feedItem {
      a {
        display: block;
        img {
          width: 100%;
          max-width: 100%;
          height: auto;
          display: block;
        }
      }
    }
  }
  @media screen and (max-width: 500px) {
    h2 {
      font-size: 2.25rem;
    }
    .feedGrid {
      grid-template-columns: repeat(2, 1fr);
      gap: 1rem;
      .feedItem:nth-child(5) {
        display: none;
      }
    }
  }
}
