.editor {
  min-height: 10rem;
  max-height: 10rem;
  width: 100%;
  flex: 0.8;
  display: flex;
  flex-direction: column;
  overflow: auto;
  .editorToolbar {
    background-color: #e5eded;
    border: 1px solid #e5eded;
    border-top-left-radius: 5px;
    border-top-right-radius: 5px;
    padding: 3px;
    display: flex;
    button {
      width: 2rem;
      height: 2rem;
      margin-left: 0.25rem;
      background-color: #f2f2f2;
      color: #171717;
      font-family: Arial, Helvetica, sans-serif;
      font-weight: 300;
      &:active {
        background-color: #e7e7e7;
      }
      &[data-inline="bold"] {
        font-weight: 900;
      }
      &[data-inline="italic"] {
        font-style: italic;
      }
      &[data-inline="underline"] {
        text-decoration: underline;
      }
    }
  }
  .editorWrapper {
    flex: 1;
    background-color: #fafafa;
    padding: 1rem;
    border: 1px solid #e5eded;
    border-bottom-left-radius: 5px;
    border-bottom-right-radius: 5px;
    font-family: "Gill Sans", "Gill Sans MT", Calibri, "Trebuchet MS",
      sans-serif;
    font-weight: 300;
  }
}
