.createPage {
  padding: 1rem;
  display: flex;
  flex-direction: column;
  h2 {
    margin-bottom: 2rem;
    font-family: "Gill Sans", "Gill Sans MT", Calibri, "Trebuchet MS",
      sans-serif;
    font-weight: 500;
    text-transform: uppercase;
    letter-spacing: 1px;
  }
  form {
    display: flex;
    margin: 0 auto;
    flex-direction: column;
    flex: 1;
    width: 100%;
    max-width: 1440px;
    padding: 0 2rem;
    fieldset {
      border: none;
      display: flex;
      margin-bottom: 1rem;
      label {
        flex: 0 0 20%;
      }
      input {
        flex: 0 0 80%;
        padding: 0.5rem;
        &[type="checkbox"] {
          flex: 0;
        }
      }
    }
    button {
      margin-left: auto;
      padding: 0.5rem 1rem;
      background: var(--primary);
      border: none;
      border-radius: 0.5rem;
      color: #fafafa;
      display: flex;
      justify-content: center;
      align-items: center;
      font-family: "Gill Sans", "Gill Sans MT", Calibri, "Trebuchet MS",
        sans-serif;
      font-weight: 500;
      text-transform: uppercase;
      letter-spacing: 1px;
      cursor: pointer;
      transition: background-color 0.3s ease;
      &:hover {
        background-color: var(--primary-dark);
      }
      &:active {
        background-color: var(--primary-darkest);
      }
      &:disabled {
        opacity: 0.5;
      }
    }
  }
}
