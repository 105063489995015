.button {
  border: 1px solid var(--primary);
  color: var(--primary);
  background: none;
  text-transform: uppercase;
  padding: 0.75rem 1.25rem 0.5rem;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  font-family: ITCJohnstonPro;
  font-size: 0.9rem;
  letter-spacing: 1px;
  line-height: 1;
  width: fit-content;
  position: relative;
  z-index: 1;
  overflow: hidden;
  transition: 0.3s ease-in-out;
}
.alignCenter {
  margin: 0 auto;
}
.button:before {
  content: "";
  top: 0;
  right: 100%;
  bottom: 0;
  width: 100%;
  height: 100%;
  position: absolute;
  background-color: var(--primary);
  z-index: -1;
  opacity: 0;
  transition: 0.3s ease-in-out;
}

.button:hover {
  color: var(--white);
}
.button:hover:before {
  right: 0;
  opacity: 1;
}
