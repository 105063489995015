.form {
  display: flex;
  max-width: 800px;
  margin: 0 auto;
  justify-content: center;
  align-items: stretch;
  gap: 2rem;
  flex-wrap: wrap;
  fieldset {
    border: none;
    display: flex;
    flex-direction: row-reverse;
    justify-content: flex-start;
    align-items: flex-end;
    flex: 1;
    margin-bottom: 1rem;
    flex-wrap: wrap;
    label {
      flex: 0.3;
      color: var(--primary);
      text-transform: uppercase;
      font-family: ITCJohnstonPro;
      font-size: 0.9rem;
      border-bottom: 1px solid var(--primary);
    }
    input {
      flex: 0.8;
      border: none;
      background-color: transparent;
      font-family: ITCJohnstonPro;
      font-weight: 300;
      font-size: 0.9rem;
      border-bottom: 1px solid var(--primary);
      color: var(--black);
      padding: 0.5rem;
    }
    textarea {
      resize: none;
      flex: 1;
      min-height: 10rem;
      border: 1px solid var(--primary);
      background: var(--white);
      padding: 0.75rem;
      font-family: ITCJohnstonPro;
      font-size: 0.9rem;
      color: var(--black);
      + label {
        flex: 0 0 100%;
        order: -1;
        border: none;
        margin-top: 3rem;
      }
    }
  }
}
.row {
  flex: 0 0 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}
.col {
  display: flex;
  flex-direction: column;
  flex: 1;
}
