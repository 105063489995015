.container {
  width: 100%;
  margin: 2rem auto;
  > div {
    margin-bottom: 1rem;
    &:last-of-type {
      margin-bottom: 0;
    }
  }
  .image {
    > img {
      width: 100%;
      height: auto;
      display: block;
      z-index: 1;
    }
    > div {
      display: none;
    }
  }
  .text {
    font-size: 1rem;
    font-family: ITCJohnstonLight;
    line-height: 2;
    color: var(--text-color);
    h3 {
      color: var(--primary);
      text-transform: uppercase;
      margin-bottom: 1.25rem;
      font-size: 1.25rem;
      font-family: ITCJohnstonPro;
      text-align: center;
      @media screen and (max-width: 767px) {
        font-size: 1.25rem;
      }
    }
    p {
      margin-bottom: 1rem;
      &:last-child {
        margin-bottom: none;
      }
    }
  }
  @media screen and(min-width: 768px) {
    margin: 5rem auto;
    display: flex;
    gap: 4.5rem;
    > div {
      width: 50%;
    }
    .text {
      line-height: 1.75;
    }
    .image {
      position: relative;
      visibility: hidden;
      &.visible {
        visibility: visible;
      }
      > img {
        width: 100%;
        height: auto;
        display: block;
        visibility: hidden;
        z-index: 1;
      }
      > div {
        width: 100%;
        height: 100% !important;
        overflow: hidden;
        position: absolute;
        display: block;
        z-index: 2;
        top: 0;
        transform-origin: center;
        > div {
          height: 100%;
          width: 100%;
          background: var(--primary);
          z-index: 2;
          position: absolute;
          top: 0;
          left: 0;
        }
        > img {
          position: absolute;
          top: 0;
          height: 100%;
          width: 100%;
          object-fit: contain;
        }
      }
    }
    &.reversed {
      flex-direction: row-reverse;
    }
    &.biggerText {
      .image {
        width: 33%;
      }
      .text {
        width: 66%;
      }
    }
  }
}
