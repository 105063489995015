.splashImage {
  width: 100%;
  margin-bottom: 2rem;
  position: relative;
  display: block;
  aspect-ratio: 2.5/1;
  background-position: top center;
  background-repeat: no-repeat;
  background-size: contain;
  &:first-child {
    margin-top: -9rem;
  }
  &.small {
    aspect-ratio: 4/1;
  }
  &.parallax {
    background-attachment: fixed;
    background-position: bottom center;
    background-repeat: no-repeat;
    background-size: cover;
  }
  &.big {
    background: none !important;
    overflow: hidden;
    position: relative;
    z-index: 1;
    img {
      transform: scale3d(1.175, 1.175, 1.175);
      opacity: 0;
      transition: transform 6s ease-out, opacity 2s ease-in;
      width: 100%;
      height: auto;
      display: block;
      object-fit: cover;
      z-index: -1;
      &.animate {
        transform: scale3d(1, 1, 1);
        opacity: 1;
        transition: transform 6s ease-out, opacity 2s ease-in;
      }
    }
  }
  &.small {
    img {
      &.hidden {
        display: none;
      }
    }
  }
  @media screen and (max-width: 767px) {
    aspect-ratio: 1 / 1;
    background-attachment: unset;
    background-position: center center;
    &.small {
      aspect-ratio: 2 / 1;
      background-attachment: unset;
      background-position: center center;
    }
    > img {
      width: 100%;
      height: 100%;
      object-fit: cover;
      display: block;
      transform: none;
      &.animate {
        width: 100%;
        height: 100%;
        object-fit: cover;
        display: block;
        transform: none;
      }
    }
  }
}
