.pagination {
  display: flex;
  justify-content: center;
  align-items: center;
  button {
    margin: 0 0.25rem;
    width: 2.5rem;
    height: 2.5rem;
    line-height: 2rem;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 50%;
    border: 2px solid var(--cms-blue-500);
    padding: 0.25rem;
    color: var(--black);
    cursor: pointer;
    &:disabled {
      opacity: 0.5;
    }
    &.currentPage {
      background-color: var(--cms-blue-100);
      border-color: var(--cms-blue-100);
      color: var(--white);
    }
  }
}
