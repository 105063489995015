.select {
  padding: 0.75rem 1rem 0.5rem;
  background-color: var(--white);
  border: 2px solid var(--primary-lightest);
  cursor: pointer;
  transition: background-color 0.3s ease;
  font-family: ITCJohnstonLight;
  font-weight: 100;
  &:hover,
  &:focus,
  &:focus-within {
    background-color: var(--primary-lightest);
    outline: none;
    border-color: var(--primary);
  }
}
