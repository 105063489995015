.footer {
  background-color: var(--white);
  border-top: 1px solid var(--gray);
  margin-top: auto;
}
.footerWrapper {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.logo {
  color: transparent;
  font-size: 0;
  display: block;
  width: 100%;
  max-width: 10rem;
  img {
    display: block;
    width: 100%;
    max-width: 100%;
    height: auto;
  }
  @media screen and (min-width: 768px) {
    max-width: 13rem;
  }
}
.sitemap {
  display: flex;
  gap: 0.5rem;
  margin: 2rem auto;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  li {
    color: var(--primary);
    text-transform: uppercase;
    font-family: ITCJohnstonPro;
    font-size: 1rem;
    @media screen and (max-width: 767px) {
      font-size: 0.8rem;
    }
    span {
      margin-left: 0.5rem;
      display: none;
    }
    a {
      color: var(--primary);
      text-transform: uppercase;
      font-family: ITCJohnstonPro;
    }
    &:last-of-type {
      span {
        display: none;
      }
    }
  }
}
.contacts {
  display: flex;
  gap: 0.5rem;
  margin: 0 auto;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  p {
    color: var(--text-color);
    font-size: 1rem;
    font-style: normal;
    font-family: BauerBodoniItalic;
    @media screen and (max-width: 767px) {
      font-size: 0.8rem;
    }
    a {
      color: var(--text-color);
    }
    span {
      @media screen and (max-width: 767px) {
        display: none;
      }
    }
    &:last-of-type {
      span {
        display: none;
      }
    }
  }
}

@media screen and (min-width: 768px) {
  .sitemap,
  .contacts {
    flex-direction: row;
  }
  .sitemap li span,
  .contacts li span:nth-of-type(2) {
    display: inline-block;
  }
}
