.main {
  background-color: var(--white);
  max-height: 0;
  overflow: hidden;
  transition: 0.3s ease;
  opacity: 0;
  ul {
    padding: 0.5rem;
    li {
      padding: 0.75rem 1rem 0.5rem;
      border: 1px dashed var(--text-color);
      background-color: var(--white);
      margin-bottom: 0.5rem;
      display: flex;
      font-family: ITCJohnstonLight;
      font-weight: 100;
      font-size: 0.9rem;
      @media screen and (max-width: 767px) {
        flex-direction: column;
      }
      &:last-child {
        margin-bottom: 0;
      }
      span {
        &:first-of-type {
          flex: 0 0 20%;
        }
      }
    }
  }
  &.open {
    max-height: 35rem;
    margin-top: 1rem;
    opacity: 1;
    overflow: hidden;
  }
}
