.userList {
  width: 100%;
  overflow: hidden;
  margin-top: 2rem;
}
.userItem {
  display: flex;
  gap: 1rem;
  &:nth-of-type(odd) {
    background-color: var(--primary-lightest);
  }
  &:first-of-type {
    background-color: var(--primary);
    div {
      font-family: ITCJohnstonPro;
      color: var(--black);
    }
  }
  > div {
    flex: 1;
    padding: 0.75rem 1rem 0.5rem;
    font-family: ITCJohnstonLight;
    font-weight: 100;
    &:first-of-type {
      flex: 0 0 10%;
    }
  }
}
