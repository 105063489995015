.dialogBox {
  position: fixed;
  z-index: 100;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.4);
  .content {
    background-color: var(--white);
    width: 100%;
    max-width: 800px;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    padding: 1rem;
    color: var(--black);
    font-size: 1rem;
    .closeBtn {
      width: 3rem;
      height: 3rem;
      display: flex;
      justify-content: center;
      align-items: center;
      color: var(--primary);
      font-size: 2rem;
      position: absolute;
      top: -0.5rem;
      right: -0.5rem;
      cursor: pointer;
      z-index: 10;
    }
  }
}
