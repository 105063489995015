.videoEmbed {
  width: 100%;
  height: 0;
  padding-bottom: 56.25%;
  position: relative;
  margin-top: 2rem;
  aspect-ratio: 16 / 9;
  iframe {
    width: 100%;
    height: 100%;
    position: absolute;
    border: none;
    top: 0;
    right: 0;
    left: 0;
    bottom: 0;
    display: block;
  }
  @media screen and (min-width: 768px) {
    margin-bottom: 2rem;
  }
}
