.button {
  margin-left: auto;
  padding: 0.75rem 1rem 0.5rem;
  border: none;
  border-radius: 0.5rem;
  display: flex;
  justify-content: center;
  align-items: center;
  font-family: ITCJohnstonLight;
  font-weight: 100;
  text-transform: uppercase;
  cursor: pointer;
  transition: background-color 0.3s ease;
  &:disabled {
    opacity: 0.5;
  }
  &.primary {
    background: var(--primary);
    color: #fafafa;
    &:hover {
      background-color: var(--primary-dark);
    }
    &:active {
      background-color: var(--primary-darkest);
    }
  }
}
