.wrapper {
  border: 1px solid #ccc;
  width: 100%;
  display: flex;
  flex-direction: column;
}
.editor {
  background-color: #e5eded;
  padding: 1rem;
  border: 1px solid #ccc;
  min-height: 20rem;
}
.toolbar {
  border: 1px solid #ccc;
  margin-bottom: 0;
}
