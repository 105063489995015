.form {
  display: flex;
  flex-direction: column;
  margin-top: 1rem;
  fieldset {
    display: flex;
    border: none;
    margin-bottom: 1rem;
    label {
      flex: 0 0 25%;
      font-family: ITCJohnstonLight;
      font-weight: 100;
      font-size: 1rem;
      padding: 0.5rem;
    }
    input {
      flex: 0 0 75%;
      border: none;
      font-family: ITCJohnstonLight;
      font-weight: 100;
      font-size: 1rem;
      padding: 0.5rem;
      background-color: var(--primary-lightest);
      border-bottom: 1px solid var(--primary-light);
    }
  }
  .userActions {
    margin-top: 1rem;
  }
}
