.grid {
  width: 100%;
  display: grid;
  gap: 1rem;
  grid-template-columns: repeat(3, 1fr);
  margin: 1rem 0 2.5rem;
  &.col-2 {
    grid-template-columns: repeat(2, 1fr);
  }
  &.col-3 {
    grid-template-columns: repeat(3, 1fr);
  }
  &.col-4 {
    grid-template-columns: repeat(4, 1fr);
  }
  &.col-5 {
    grid-template-columns: repeat(5, 1fr);
  }
  @media screen and (max-width: 767px) {
    display: flex;
    flex-wrap: nowrap;
    overflow: hidden;
  }
}

.bullets {
  display: none;
  @media screen and (max-width: 767px) {
    display: flex;
    gap: 1rem;
    align-items: center;
    justify-content: center;
    margin-top: 1.5rem;
    span {
      width: 0.5rem;
      height: 0.5rem;
      background-color: var(--primary);
      border-radius: 50%;
      opacity: 0.5;
      &.active {
        opacity: 1;
      }
    }
  }
}

.dialog {
  width: 100%;
  position: relative;
  img {
    width: 100%;
    height: auto;
    display: block;
  }
  button {
    position: absolute;
    top: 50%;
    width: 2.5rem;
    height: 2.5rem;
    border-radius: 50%;
    border: none;
    color: var(--white);
    background-color: var(--primary);
    font-size: 2rem;
    font-family: BauerBodoniBold;
    transform: translateY(-50%);
    transition: 0.3s ease;
    cursor: pointer;
    &:hover {
      background-color: var(--primary-dark);
    }
    &:active {
      background-color: var(--primary-darkest);
    }
    &:disabled {
      opacity: 0.4;
      cursor: default;
      pointer-events: none;
      &:hover {
        background-color: var(--primary);
      }
      &:active {
        background-color: var(--primary);
      }
    }
    &:first-of-type {
      left: 0.5rem;
    }
    &:last-of-type {
      right: 0.5rem;
    }
  }
}
