.hero {
  position: relative;
  margin-bottom: 4rem;
  img {
    width: 100%;
    max-width: 100%;
    height: auto;
    display: block;
  }
  div {
    position: absolute;
    bottom: 0;
    background-color: #f5f5f5;
    min-width: 75%;
    padding-top: 1rem;
    h1 {
      color: var(--primary);
      text-transform: uppercase;
      font-family: ITCJohnstonPro;
      margin-bottom: 0.5rem;
      font-size: 1.25rem;
    }
    p {
      font-size: 1rem;
      font-style: italic;
      font-weight: 100;
      font-family: BauerBodoniItalic;
      color: var(--gray);
      white-space: pre-wrap;
    }
  }
  @media screen and (min-width: 768px) {
    div {
      padding-top: 2rem;
      h1 {
        font-size: 2rem;
        margin-bottom: 1rem;
      }
      p {
        font-size: 1.125rem;
      }
    }
  }
}
