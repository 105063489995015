.login {
  background-color: var(--primary);
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  form {
    background-color: var(--white);
    padding: 1rem;
    width: 100%;
    max-width: 600px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    border-radius: 0.5rem;
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
    .brand {
      width: 100%;
      max-width: 10rem;
      margin-top: 1rem;
      img {
        width: 100%;
        height: auto;
        display: block;
      }
    }
    h1 {
      color: var(--primary);
      font-family: BauerBodoniBold;
      font-weight: 300;
      font-size: 1.5rem;
      margin: 2rem auto;
    }
    fieldset {
      width: 100%;
      border: none;
      margin-bottom: 2rem;
      max-width: 300px;
      label {
        width: 100%;
        border: 1px solid var(--primary);
        position: relative;
        display: flex;
        align-items: center;
        padding: 0.75rem 0.5rem 0.5rem;
        background-color: transparent;
        z-index: 1;
        span {
          position: absolute;
          color: var(--primary);
          transition: 0.3s ease;
          bottom: auto;
          font-size: 0.8rem;
          text-transform: uppercase;
          transform: translateY(-50%);
          padding-top: 0.25rem;
          font-family: ITCJohnstonPro;
          top: 50%;
          z-index: -1;
        }
        input {
          width: 100%;
          border: none;
          font-family: BauerBodoniItalic;
          font-weight: 100;
          color: var(--gray);
          background-color: transparent;
          &.noPlaceholder,
          &:focus {
            outline: none;
            & + span {
              top: 0;
              background-color: var(--white);
            }
          }
        }
      }
    }

    button {
      padding: 0.75rem 1rem 0.5rem;
      font-size: 0.9rem;
      font-family: ITCJohnstonPro;
      text-transform: uppercase;
      background-color: var(--primary);
      color: var(--white);
      border: none;
      border-radius: 0.5rem;
      cursor: pointer;
      margin-bottom: 1rem;
      transition: background-color 0.3s ease;
      &:hover {
        background-color: var(--primary-dark);
      }
      &:active {
        background-color: var(--primary-darkest);
      }
    }
  }
}
