.dashboardTables {
  display: grid;
  grid-template-columns: 1fr;
  gap: 1rem;
  width: 100%;
  max-width: 1440px;
  margin: 0 auto;
  @media screen and (min-width: 768px) {
    grid-template-columns: 1fr 1fr;
  }
  @media screen and (min-width: 1240px) {
    grid-template-columns: 1fr 1fr 1fr;
  }
  .adminTable {
    background-color: var(--primary);
    padding: 1rem;
    h3 {
      margin-bottom: 1rem;
      font-family: ITCJohnstonLight;
      font-weight: 100;
      text-transform: uppercase;
      font-size: 1rem;
      letter-spacing: 1px;
      display: flex;
      align-items: center;
      justify-content: space-between;
    }
    .collectionRow {
      margin-bottom: 5px;
      a {
        padding: 0.5rem;
        background: #fafafa;
        cursor: pointer;
        display: block;
        &:hover {
          background: #f5f5f5;
        }
        p {
          color: #171717;
          display: flex;
          justify-content: center;
          align-items: center;
          text-transform: uppercase;
          font-family: ITCJohnstonLight;
          font-weight: 100;
          font-size: 0.8rem;
          span {
            margin-left: auto;
            font-size: 0.8rem;
            text-transform: none;
            font-weight: 300;
          }
        }
      }
    }
  }
}
