.tooltip {
  width: 1rem;
  height: 1rem;
  position: relative;
  .tooltipDialog {
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    svg {
      width: 100%;
      stroke: var(--primary);
    }
  }
  .tooltipDialogBox {
    position: absolute;
    padding: 1rem;
    width: max-content;
    max-width: 250px;
    font-size: 0.7rem;
    border: 1px solid var(--primary);
    background-color: white;
    top: 0.5rem;
    left: 50%;
    transform: translateX(-50%);
    display: none;
    z-index: 2;
  }
  &:hover {
    .tooltipDialogBox {
      display: block;
    }
  }
}
