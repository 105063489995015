.adminSidebar {
  width: 100%;
  max-width: 300px;
  background-color: var(--primary);
  nav {
    padding: 1rem;
    position: sticky;
    top: 0;
    .menuBlock {
      position: relative;
      h3 {
        width: 100%;
        padding: 1rem;
        display: flex;
        justify-content: center;
        align-items: center;
        color: #121212;
        text-transform: uppercase;
        font-size: 0.8rem;
        letter-spacing: 1px;
        cursor: pointer;
        position: relative;
        span {
          position: absolute;
          right: 0;
          transform: rotate(90deg);
          cursor: pointer;
        }
        &.open {
          span {
            transform: rotate(-90deg);
          }
        }
      }
      ul {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        position: relative;
        overflow: hidden;
        max-height: 0;
        transition: 0.3s ease;
        &:after {
          content: "";
          display: block;
          height: 1px;
          width: 80%;
          background-color: var(--primary-light);
          margin: 0 auto;
        }
        &.open {
          max-height: 50rem;
          &:after {
            content: "";
            display: block;
            height: 1px;
            width: 80%;
            background-color: var(--primary-light);
          }
        }
        li {
          width: 100%;
          margin-bottom: 0.25rem;
          a {
            width: 100%;
            padding: 1rem;
            display: flex;
            justify-content: center;
            align-items: center;
            color: #121212;
            text-transform: uppercase;
            font-size: 0.7rem;
            letter-spacing: 1px;
            cursor: pointer;
            &:hover {
              background-color: var(--primary-dark);
            }
            &:active {
              background-color: var(--primary-darkest);
            }
          }
        }
      }
      &:last-of-type {
        ul {
          &:after {
            display: none;
          }
        }
      }
    }
  }
  @media screen and (max-width: 767px) {
    display: none;
  }

  &.dark {
    background-color: var(--primary-darkest);
    nav {
      .menuBlock {
        h3 {
          color: var(--primary);
        }
        ul {
          &:after {
            background-color: var(--primary-dark);
          }
          li {
            a {
              color: var(--text-color);
            }
          }
        }
      }
    }
  }
}
