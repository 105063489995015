.page {
  padding: 1rem;
  height: 100%;
  h2 {
    font-family: ITCJohnstonLight;
    font-weight: 100;
    text-transform: uppercase;
    letter-spacing: 1px;
    max-width: 1440px;
    margin: 1rem auto 1.5rem;
  }
}
