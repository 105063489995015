.adminHeader {
  background-color: var(--primary);
  padding: 1rem 0;
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-bottom: 2px solid var(--white);

  .brandLogo {
    width: 100%;
    max-width: 300px;
    display: flex;
    justify-content: center;
    align-items: center;
    a {
      display: block;
      width: 100%;
      max-width: 150px;
      img {
        width: 100%;
        max-width: 100%;
        height: auto;
        display: block;
      }
    }
  }

  .userActions {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    position: relative;
    margin-right: 1rem;
    z-index: 1;
    div {
      background-color: var(--primary);
      border: none;
      display: flex;
      justify-content: center;
      align-items: center;
      color: #121212;
      text-transform: uppercase;
      font-size: 0.7rem;
      letter-spacing: 0.125rem;
      cursor: pointer;
      padding: 1rem;
      font-family: "Gill Sans", "Gill Sans MT", Calibri, "Trebuchet MS",
        sans-serif;
      &:hover {
        background-color: var(--primary-dark);
      }
      &:active {
        background-color: var(--primary-darkest);
      }
      &:after {
        content: "\25bc";
        font-family: "Gill Sans", "Gill Sans MT", Calibri, "Trebuchet MS",
          sans-serif;
        display: inline-block;
        font-size: 0.375rem;
        padding-left: 0.5rem;
      }
      ul {
        display: none;
        position: absolute;
        top: 100%;
        left: 0;
        right: 0;
        width: 100%;
        background-color: var(--white);
        border: 1px solid var(--primary);
        hr {
          border-color: var(--primary);
          border-width: 1px;
        }
        li {
          a,
          button {
            width: 100%;
            display: flex;
            align-items: center;
            justify-content: center;
            padding: 1rem;
            color: var(--black);
            border: none;
            background: none;
            font-family: "Gill Sans", "Gill Sans MT", Calibri, "Trebuchet MS",
              sans-serif;
            text-transform: uppercase;
            font-size: 0.7rem;
            letter-spacing: 0.125rem;
            z-index: 2;
            cursor: pointer;
            &:hover {
              background-color: var(--primary-lightest);
            }
          }
        }
      }
      &:focus ul,
      &:active ul {
        display: block;
      }
    }
  }
  &.dark {
    background-color: var(--primary-darkest);
    border-bottom: 2px solid var(--black);
    .userActions {
      div {
        background-color: var(--primary-darkest);
        color: var(--text-color);
        &:hover {
          background-color: var(--primary-dark);
        }
        &:active {
          background-color: var(--primary);
        }
      }
      ul {
        background-color: var(--primary-darkest);
        li {
          a,
          button {
            color: var(--text-color);
            &:hover {
              background-color: var(--primary-dark);
            }
          }
        }
      }
    }
  }
}
