.relatedEntries {
  display: flex;
  gap: 3rem;
  padding-top: 3rem;
  border-top: 1px solid var(--gray);
  align-items: stretch;
  justify-content: center;
  @media screen and(max-width: 767px) {
    flex-direction: column;
  }
}
