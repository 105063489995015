.container {
  width: 100%;
  max-width: 1440px;
  padding: 0 1rem;
  margin: 0 auto;
}
.containerSmall {
  width: 100%;
  max-width: 800px;
  padding: 0 1rem;
  margin: 0 auto;
}
