.preview {
  margin-top: 2rem;
  .previewItem {
    display: flex;
    align-items: flex-start;
    margin-bottom: 1rem;
    border-top: 1px solid var(--primary-light);
    padding-top: 1rem;
    font-family: ITCJohnstonLight;
    font-weight: 100;
    &:first-of-type {
      border: none;
      padding-top: 0;
    }
    span {
      font-size: 0.6rem;
      margin-right: 1rem;
      width: 100px;
      max-width: 100px;
      min-width: 100px;
      padding: 0.5rem 0.5rem 0.25rem;
      font-family: ITCJohnstonPro;
      font-weight: 100;
      text-transform: uppercase;
      &.Heading {
        background-color: red;
      }
      &.Paragraph {
        background-color: orange;
      }
      &.Grid {
        background-color: yellowgreen;
      }
      &.SplashImage {
        background-color: steelblue;
      }
      &.NavLink {
        background-color: thistle;
      }
      &.ExtLink {
        background-color: rosybrown;
      }
      &.ImageText {
        background-color: seagreen;
      }
    }
    p {
      flex: 1;
      font-size: 0.9rem;
      line-height: 1.5;
      margin-right: 3rem;
      white-space: pre-line;
    }
    .previewGallery {
      display: flex;
      flex: 1;
      gap: 1rem;
      flex-wrap: wrap;
      > div {
        flex: 0 0 7%;
        min-width: 75px;
        img {
          width: 100%;
          height: 100%;
          object-fit: cover;
          display: block;
        }
      }
    }
    .previewImgText {
      display: flex;
      img {
        flex: 0 0 35%;
        max-width: 200px;
      }
      div {
        margin-left: 1rem;
        flex: 0 0 65%;
        flex: 1;
        font-size: 0.8rem;
        margin-right: 3rem;
      }
    }
    .previewSplashImage {
      flex: 0 0 35%;
      max-width: 200px;
      img {
        width: 100%;
        height: 100%;
        object-fit: cover;
        display: block;
      }
    }
    select {
      border-radius: 0;
      font-family: ITCJohnstonLight;
      font-weight: 100;
      padding: 0.5rem 0.5rem 0.25rem;
      border: 2px solid var(--primary);
      margin-left: auto;
      cursor: pointer;
      background-color: var(--primary-lightest);
    }
    button {
      width: 2rem;
      height: 2rem;
      margin-left: 1rem;
      background-color: transparent;
      border: none;
      cursor: pointer;
      &:first-of-type {
        svg {
          stroke: var(--cms-blue-500);
        }
      }
      &:last-of-type {
        svg {
          stroke: var(--cms-red-500);
        }
      }
    }
  }
}
