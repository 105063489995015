.list {
  display: flex;
  flex-direction: column;
  width: 100%;
  max-width: 1440px;
  margin: 0 auto;
  .emptyList {
    padding: 1rem;
    background-color: #e7e7e7;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    p {
      width: 100%;
      max-width: 600px;
      text-align: center;
      margin: 0.5rem 0;
      color: #171717;
    }
  }

  .collectionList {
    width: 100%;
    display: flex;
    flex-direction: column;
    margin: 1rem auto;
    .collectionListHeader {
      display: flex;
      background-color: var(--primary);
      font-family: ITCJohnstonLight;
      font-weight: 100;
      div {
        padding: 0.75rem 0.5rem 0.5rem;
        flex: 1;
        display: flex;
        justify-content: flex-start;
        align-items: center;
        font-family: ITCJohnstonLight;
        font-weight: 100;
        &[data-type="id"],
        &[data-type="updatedAt"],
        &[data-type="createdAt"] {
          display: none;
        }
        &[data-type="thumbnail"] {
          order: -1;
          max-width: 150px;
        }
        &[data-type="actions"] {
          flex: 0;
          width: 3rem;
          color: transparent;
          opacity: 0;
          visibility: hidden;
        }
      }
    }
  }
}
