.container {
  display: flex;
  gap: 1px;
  margin-top: 2rem;
  align-items: center;
  justify-content: flex-start;
  button {
    padding: 0.75rem 0.5rem 0.5rem;
    border: none;
    background-color: #e5eded;
    color: var(--text-color);
    text-transform: uppercase;
    font-family: ITCJohnstonLight;
    font-weight: 100;
    cursor: pointer;
    line-height: 1;
    &.active {
      background-color: var(--white);
      font-family: ITCJohnstonPro;
      font-weight: 100;
      color: var(--black);
      cursor: default;
      pointer-events: none;
    }
  }
}
