.heading {
  color: var(--primary);
  text-transform: uppercase;
  margin: 5rem auto;
  font-size: 2rem;
  font-family: ITCJohnstonPro;
  text-align: center;
  @media screen and (max-width: 767px) {
    margin: 2rem auto;
    font-size: 1.5rem;
  }
}
