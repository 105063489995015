.extLink {
  border: 1px solid var(--primary);
  margin: 0 auto 5rem;
  color: var(--primary);
  background: none;
  text-transform: uppercase;
  padding: 0.75rem 1.25rem 0.5rem;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  font-family: ITCJohnstonPro;
  font-size: 0.9rem;
  letter-spacing: 1px;
  line-height: 1;
  width: fit-content;
  position: relative;
  z-index: 1;
  overflow: hidden;
  transition: 0.3s ease-in-out;
  margin-top: -2rem;
  &:before {
    content: "";
    top: 0;
    right: 100%;
    bottom: 0;
    width: 100%;
    height: 100%;
    position: absolute;
    background-color: var(--primary);
    z-index: -1;
    opacity: 0;
    transition: 0.3s ease-in-out;
  }

  &:hover {
    color: var(--white);
    &:before {
      right: 0;
      opacity: 1;
    }
  }
  @media screen and (max-width: 767px) {
    margin: 0 auto 2rem;
  }
}
