/* Variables */
:root {
  --primary: hsl(295, 22%, 58%);
  --primary-light: hsl(295, 22%, 78%);
  --primary-lightest: hsl(295, 22%, 92%);
  --primary-dark: hsl(295, 22%, 38%);
  --primary-darkest: hsl(295, 22%, 22%);
  --gray: #808080;
  --white: #ffffff;
  --black: #121212;

  --text-color: #80847f;

  --cms-blue-100: hsl(203.3, 32.8%, 52.2%);
  --cms-blue-300: hsl(203.3, 32.7%, 59.8%);
  --cms-blue-500: hsl(203.3, 32.5%, 67.5%);
  --cms-blue-700: hsl(203.4, 32.3%, 75.1%);
  --cms-blue-900: hsl(203.6, 31.8%, 82.7%);

  --cms-red-100: hsl(356.5, 95.8%, 27.8%);
  --cms-red-300: hsl(356.9, 95.1%, 31.8%);
  --cms-red-500: hsl(356.5, 95.6%, 35.5%);
  --cms-red-700: hsl(356.3, 95.8%, 47.1%);
  --cms-red-900: hsl(356.4, 95.3%, 58.4%);

  --width-lg: 1440px;
  --width-sm: 900px;
}

@font-face {
  font-family: BauerBodoniItalic;
  src: url("./assets/fonts/BauerBodoniStd-Italic.otf");
}
@font-face {
  font-family: BauerBodoniBold;
  src: url("./assets/fonts/BauerBodoniStd-Bold.otf");
}
@font-face {
  font-family: ITCJohnstonPro;
  src: url("./assets/fonts/ITCJohnstonProBold.otf");
}
@font-face {
  font-family: ITCJohnstonLight;
  src: url("./assets/fonts/ITCJohnstonStdLight.otf");
}

/* Reset */
* {
  margin: 0;
  padding: 0;
  list-style: none;
  text-decoration: none;
  box-sizing: border-box;
}
html {
  font-size: 100%;
}
body {
  font-family: Arial, Helvetica, sans-serif;
}
html,
body,
#root {
  width: 100%;
  height: 100%;
}
#root {
  display: flex;
  flex-direction: column;
}
main {
  flex: 1;
  background-color: #f5f5f5;
  display: flex;
  flex-direction: column;
}

/* Overrides */

.rdw-left-aligned-block .public-DraftStyleDefault-ltr {
  text-align: left !important;
}

.rdw-center-aligned-block .public-DraftStyleDefault-ltr {
  text-align: center !important;
}

.rdw-right-aligned-block .public-DraftStyleDefault-ltr {
  text-align: right !important;
}
