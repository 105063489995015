.filters {
  margin-bottom: 1rem;
  padding: 1rem 0.5rem;
  display: flex;
  gap: 1rem;
  background-color: var(--primary);
  fieldset {
    border: none;
    margin: 0;
    display: flex;
    align-items: center;
    label {
      font-size: 0.8rem;
    }
    select {
      padding: 0.25rem 0.5rem;
      margin-left: 1rem;
      font-size: 0.8rem;
    }
  }
}
