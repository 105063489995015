.editPage {
  padding: 1rem;
  height: 100%;
  h2 {
    font-family: ITCJohnstonLight;
    font-weight: 100;
    text-transform: uppercase;
    letter-spacing: 1px;
    width: 100%;
    max-width: 1440px;
    margin: 1rem auto 1.5rem;
  }
  form {
    display: flex;
    margin: 0 auto;
    flex: 1;
    width: 100%;
    max-width: 1440px;
    .container {
      margin-top: 1rem;
      width: 100%;
      height: 100%;
      fieldset {
        border: none;
        display: flex;
        margin-bottom: 1rem;
        label {
          flex: 0 0 20%;
          font-family: ITCJohnstonLight;
          font-weight: 100;
        }
        input {
          flex: 0 0 80%;
          padding: 0.5rem;
          font-family: ITCJohnstonLight;
          font-weight: 100;
          &[type="checkbox"] {
            flex: 0;
          }
        }
      }
      section {
        margin-bottom: 2rem;
        background-color: var(--white);
        padding: 1rem;
        border-radius: 0.5rem;
        border-top-left-radius: 0;
        box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
      }
    }
  }
  &:not(:first-child) {
    display: none;
  }
}
.dialog {
  h3 {
    margin-bottom: 1rem;
    font-family: ITCJohnstonLight;
    font-weight: 100;
  }
  .preview {
    width: 100%;
    display: flex;
    gap: 1rem;
    margin: 1rem auto;
    align-items: stretch;
    position: relative;
    > div {
      flex: 0 0 13%;
      position: relative;
      img {
        width: 100%;
        height: 100%;
        display: block;
        object-fit: cover;
      }
      button {
        position: absolute;
        top: 0;
        right: 0;
        width: 2rem;
        height: 2rem;
        background-color: rgba(0, 0, 0, 0.2);
        border-radius: 50%;
        border-top-right-radius: 0;
        border: none;
        cursor: pointer;
        display: none;
        svg {
          stroke: var(--cms-red-500);
        }
      }
      &:hover {
        button {
          display: block;
        }
      }
    }
  }
  fieldset {
    margin-bottom: 1rem;
    display: flex;
    border: none;
    label {
      flex: 0 0 30%;
      display: flex;
      font-family: ITCJohnstonLight;
      font-weight: 100;
      > div {
        margin-left: auto;
        margin-right: 1rem;
      }
    }
    textarea {
      min-height: 20rem;
      resize: none;
    }
    .wrapper {
      border: 1px solid #ccc;
      min-height: 30rem;
      width: 100%;
      display: flex;
      flex-direction: column;
    }
    .editor {
      background-color: #e5eded;
      padding: 1rem;
      border: 1px solid #ccc;
    }
    .toolbar {
      border: 1px solid #ccc;
      margin-bottom: 0;
    }
    textarea,
    input {
      flex: 0 0 70%;
      padding: 0.5rem;
    }
    input[type="checkbox"] {
      display: flex;
      justify-content: flex-start;
      flex: 0;
    }
    input[type="file"] {
      padding-left: 0;
    }
  }
  .dialogButtons {
    display: flex;
    margin-top: 2rem;
    gap: 1rem;
    justify-content: center;
    align-items: center;
    button {
      padding: 0.5rem 1rem;
    }
  }
}
.userActions {
  margin-top: auto;
  display: flex;
}
