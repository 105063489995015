.card {
  background-color: var(--white);
  padding: 1rem;
  border-radius: 0.5rem;
  width: 100%;
  max-width: 1440px;
  margin: 0 auto;
  overflow: hidden;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
}
.header {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-bottom: 0.5rem;
  border-bottom: 3px solid var(--primary-lightest);
  h3 {
    font-size: 1rem;
    font-family: ITCJohnstonPro;
    font-weight: 100;
    color: var(--primary);
  }

  div {
    display: flex;
    button {
      &:last-of-type {
        width: 2rem;
        height: 2rem;
        margin-left: 1rem;
        display: flex;
        justify-content: center;
        align-items: center;
        font-family: ITCJohnstonBold;
        font-weight: 100;
        font-size: 2rem;
        padding-left: 0.25rem;
        padding-bottom: 0.125rem;
        color: var(--primary);
        background-color: transparent;
        border: none;
        cursor: pointer;
        border-radius: 50%;
        transition: 0.3s ease;
        &:hover {
          background-color: var(--primary-lightest);
        }
        &:active {
          background-color: var(--primary-light);
        }
        &.active {
          transform: rotate(90deg);
        }
      }
    }
  }
  &:only-child {
    border: 0;
  }
}
