.createUser {
  width: 100%;
  height: 100%;
  position: fixed;
  z-index: 10;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background-color: rgba(0, 0, 0, 0.6);
  display: flex;
  justify-content: center;
  align-items: center;
  form {
    background-color: var(--white);
    padding: 1rem;
    width: 100%;
    max-width: 500px;
    h2 {
      color: var(--cms-blue-300);
      padding: 0.5rem;
      border-bottom: 3px solid var(--cms-blue-300);
      margin-bottom: 1rem;
    }
    .errors {
      background-color: #ff7f7f;
      padding: 0.5rem;
      font-size: 0.8rem;
      margin: 0 auto 1rem;
    }
    fieldset {
      border: none;
      display: flex;
      width: 100%;
      margin-bottom: 2rem;
      align-items: center;
      label {
        flex: 0 0 35%;
        color: var(--cms-blue-100);
      }
      input,
      select {
        flex: 0 0 65%;
        padding: 0.5rem;
      }
    }
    div {
      display: flex;
      gap: 1rem;
      margin: 1rem auto;
      justify-content: center;
      button {
        margin: 0;
        font-weight: 600;
        &[type="reset"] {
          background-color: transparent;
          color: var(--cms-red-500);
        }
      }
    }
  }
}
