.container {
  // border: 1px solid var(--white);
  position: fixed;
  z-index: 100;
  border-radius: 1rem;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
  color: var(--text-color);
  &.info {
    background-color: var(--white);
    color: var(--primary);
    // border: 1px solid var(--primary);
  }
  .toast {
    position: relative;
    padding: 0.5rem;
    .title {
      padding-bottom: 1rem;
      font-size: 0.9rem;
      border-bottom: 3px solid var(--white);
      font-family: ITCJohnstonLight;
    }
    .message {
      font-size: 1rem;
      padding: 0.75rem 1rem 0.5rem;
      text-transform: uppercase;
      font-family: ITCJohnstonLight;
      display: flex;
      align-items: center;
      span {
        margin-right: 1rem;
        svg {
          width: 2rem;
          height: 2rem;
        }
      }
    }
  }
  &.topRight {
    top: 1rem;
    right: 1rem;
    transition: transform 0.6s ease-in-out;
    animation: toast-in-right 0.7s;
  }
  &.bottomRight {
    bottom: 1rem;
    right: 1rem;
    transition: transform 0.6s ease-in-out;
    animation: toast-in-right 0.7s;
  }
  &.topLeft {
    top: 1rem;
    left: 1rem;
    transition: transform 0.6s ease-in;
    animation: toast-in-left 0.7s;
  }
  &.bottomLeft {
    bottom: 1rem;
    left: 1rem;
    transition: transform 0.6s ease-in;
    animation: toast-in-left 0.7s;
  }
}

@keyframes toast-in-right {
  from {
    transform: translateX(100%);
  }
  to {
    transform: translateX(0);
  }
}

@keyframes toast-in-left {
  from {
    transform: translateX(-100%);
  }
  to {
    transform: translateX(0);
  }
}
