.relatedEntriesItem {
  flex: 0 0 33.33%;
  display: flex;
  flex-direction: column;
  img {
    width: 100%;
    max-width: 100%;
    height: auto;
    display: block;
    margin-bottom: 1rem;
  }
  h2 {
    color: var(--primary);
    text-transform: uppercase;
    font-family: ITCJohnstonPro;
    margin-bottom: 1rem;
  }
  p {
    font-size: 1rem;
    font-weight: 100;
    font-family: BauerBodoniItalic;
    color: var(--gray);
    line-height: 1.25rem;
    margin-bottom: 2rem;
    p {
      display: none;
      &:first-child {
        display: block;
      }
    }
  }
  a {
    margin-top: auto;
    border: 1px solid var(--primary);
    color: var(--primary);
    background: none;
    text-transform: uppercase;
    padding: 0.75rem 1.25rem 0.5rem;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    font-family: ITCJohnstonPro;
    font-weight: 600;
    font-size: 0.9rem;
    letter-spacing: 1px;
    line-height: 1;
    width: fit-content;
    position: relative;
    z-index: 1;
    overflow: hidden;
    transition: 0.3s ease-in-out;
    &:before {
      content: "";
      top: 0;
      right: 100%;
      bottom: 0;
      width: 100%;
      height: 100%;
      position: absolute;
      background-color: var(--primary);
      z-index: -1;
      opacity: 0;
      transition: 0.3s ease-in-out;
    }
    &:hover {
      color: var(--white);
      &:before {
        right: 0;
        opacity: 1;
      }
    }
  }
}
@media screen and (max-width: 767px) {
  .relatedEntriesItem {
    flex: 0 0 100%;
    h2 {
      font-size: 1.25rem;
    }
    p {
      font-size: 1rem;
      line-height: 1.5;
    }
    a {
      margin-left: auto;
      &:before {
        display: none;
      }
      &:hover {
        &:before {
          display: none;
        }
      }
    }
  }
}
