.adminLayout {
  position: relative;
  height: 100%;
  display: flex;
  flex-direction: column;
}
.main {
  flex: 1;
  display: flex;
  padding: 0;
  flex-direction: row;
}
