.userActions {
  width: 100%;
  max-width: 1440px;
  margin: 0 auto;
  display: flex;
  font-family: ITCJohnstonLight;
  font-weight: 100;
  align-items: center;
  a {
    margin-left: auto;
    padding: 0.5rem 1rem;
    background: var(--primary);
    border: none;
    border-radius: 0.5rem;
    font-size: 0.8rem;
    color: #fafafa;
    display: flex;
    justify-content: center;
    align-items: center;
    font-family: ITCJohnstonLight;
    font-weight: 100;
    text-transform: uppercase;
    letter-spacing: 1px;
    cursor: pointer;
    transition: background-color 0.3s ease;
    &:hover {
      background-color: var(--primary-dark);
    }
    &:active {
      background-color: var(--primary-darkest);
    }
  }
}
.breadcrumbs {
  width: 100%;
  max-width: 1440px;
  margin: 1rem auto;
  margin-bottom: 2rem;
  font-family: ITCJohnstonLight;
  font-weight: 100;
  text-transform: uppercase;
  letter-spacing: 1px;
}
