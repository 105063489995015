.pageInfo {
  background-color: var(--white);
  border-radius: 0.5rem;
  h3 {
    margin-bottom: 1rem;
    font-size: 1rem;
    text-transform: uppercase;
    font-family: ITCJohnstonPro;
    font-weight: 100;
  }
}
