.imageUpload {
  width: 100%;
  max-width: 100%;
  display: flex;
  align-items: center;
  .thumbnail {
    width: 15%;
    aspect-ratio: 16 / 9;
    overflow: hidden;
    position: relative;
    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
    button {
      position: absolute;
      top: 0;
      bottom: 0;
      right: 0;
      left: 0;
      width: 100%;
      height: 100%;
      border: none;
      background-color: rgba(0, 0, 0, 0.4);
      color: var(--white);
      display: none;
    }
    &:hover {
      button {
        display: flex;
        cursor: pointer;
      }
    }
    margin-right: 1rem;
  }
  input {
    font-size: 0.8rem;
  }
}

.dialogContent {
  h3 {
    margin-bottom: 1rem;
  }
  .userActions {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    margin-top: 1rem;
    button {
      margin-left: 1rem;
    }
  }
}
