.gridItem {
  overflow: hidden;
  position: relative;
  z-index: 1;
  visibility: hidden;
  &.visible {
    visibility: visible;
  }
  > img {
    width: 100%;
    height: 100%;
    max-height: 100%;
    display: block;
    object-fit: cover;
    visibility: hidden;
    z-index: 1;
  }
  > div {
    width: 100%;
    height: 100% !important;
    overflow: hidden;
    position: absolute;
    z-index: 2;
    top: 0;
    transform-origin: center;
    > div {
      height: 100%;
      width: 100%;
      background: var(--primary);
      z-index: 2;
      position: absolute;
      top: 0;
      left: 0;
    }
    > img {
      position: absolute;
      top: 0;
      height: 100%;
      width: 100%;
      object-fit: cover;
    }
  }
  &.clickable {
    cursor: pointer;
  }
  @media screen and (max-width: 767px) {
    flex: 0 0 100%;
    opacity: 0;
    aspect-ratio: none;
    transform: translateX(100%);
    transition: 0.3s ease-in-out;
    display: block;
    &.one-one {
      aspect-ratio: 1 / 1;
    }
    &.two-one {
      aspect-ratio: 2 / 1;
    }
    &.one-two {
      aspect-ratio: 1 / 2;
    }
    &.active {
      transform: translateX(0);
      opacity: 1;
      order: -1;
    }
    > img {
      width: 100%;
      height: 100%;
      object-fit: cover;
      display: block;
      object-fit: initial;
      visibility: visible;
      z-index: 1;
    }
    > div {
      display: none;
    }
  }
}
