.pagination {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 0.5rem;
  margin: 2rem auto;
  width: 100%;
  button {
    width: 2rem;
    height: 2rem;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 1rem;
    line-height: 1rem;
    font-family: ITCJohnstonPro;
    text-align: center;
    color: var(--primary);
    border: 1px solid var(--primary);
    background-color: var(--white);
    cursor: pointer;
    &:disabled {
      opacity: 0.3;
      cursor: default;
      pointer-events: none;
    }
  }
  .pageButton {
    &.active {
      background-color: var(--primary);
      color: var(--white);
    }
  }
}
