.caseHistoryLabel {
  width: 100%;
  background-color: hsla(270, 50%, 40%, 0.4);
  height: 4rem;
  transform: translateY(-6rem);
  z-index: 2;
  padding: 0 1rem;
  overflow: hidden;
}
.wrapper {
  display: flex;
  align-items: stretch;
  height: 100%;
  flex: 1;
  width: 100%;
  max-width: var(--width-lg);
  margin: 0 auto;
}
.info {
  background-color: var(--white);
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 1.5rem 0;
  position: relative;
  height: 100%;
}
.info:after {
  content: "";
  display: block;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 100%;
  transform: translateX(-50%);
  width: 0;
  height: 100%;
  border: 4rem solid transparent;
  border-top-color: var(--white);
  z-index: -1;
}
.info:before {
  content: "";
  display: block;
  position: absolute;
  top: 0;
  bottom: 0;
  right: 100%;
  width: 100vw;
  background-color: var(--white);
}
.name {
  font-size: 1.25rem;
  font-family: ITCJohnstonPro;
  color: var(--primary);
  text-transform: uppercase;
  line-height: 1;
}
.date {
  font-size: 0.75rem;
  font-family: ITCJohnstonPro;
  color: var(--primary);
  text-transform: uppercase;
  line-height: 1;
}
.label {
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 1rem;
  margin-left: 3rem;
  color: var(--white);
  text-transform: uppercase;
  font-size: 1.25rem;
  font-family: ITCJohnstonPro;
  line-height: 1;
  margin-bottom: -0.25em;
}
