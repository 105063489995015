.mediaGallery {
  input {
    margin-top: 0.5rem;
  }
  .gallery {
    display: flex;
    gap: 1rem;
    flex-wrap: wrap;
    margin-top: 1rem;
    .item {
      width: 7rem;
      height: 7rem;
      border-radius: 1rem;
      position: relative;
      img {
        width: 100%;
        height: auto;
        display: block;
      }
    }
  }
}
