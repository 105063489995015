.scrollTop {
  width: 3rem;
  height: 3rem;
  font-size: 1.5rem;
  background-color: var(--primary);
  color: var(--white);
  transition: 0.3s ease-in-out;
  position: fixed;
  bottom: 1rem;
  right: 1rem;
  border: none;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  line-height: 0;
  text-align: center;
  font-family: ITCJohnstonStd;
  cursor: pointer;
  transform: translateY(3rem);
  opacity: 0;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
  span {
    transform: rotate(-90deg);
    transition: 0.3s ease-in-out;
  }
  &:hover {
    background-color: var(--primary-dark);
    box-shadow: 0 8px 16px 0 rgba(0,0,0,0.2);
  }
  &:active {
    background-color: var(--primary-darkest);
  }
  &.visible {
    transform: translateY(0);
    opacity: 1;
  }
}
